<template>
  <div
    class="foot-box flex-cs"
    :style="position === 'rel' ? 'position:relative' : ''"
  >
    <div class="foot-logo">
      <img src="../assets/images/logo-h.png" class="" />
    </div>
    <div class="info">
      <div class="title">联系我们</div>
      <div class="divider"></div>
      <div class="info-content" style="z-index: 999; position: relative">
        商业合作：19306284060<br />
        <!-- 媒体咨询：<a
          href="https://www.x-vup.cn"
          target="_blank"
          style="color: #bbb"
          >www.x-vup.com</a
        >
        /
        <a href="https://www.x-vup.cn" target="_blank" style="color: #bbb"
          >www.x-vup.cn</a
        ><br /> -->
        公司地址：江苏省南通市崇川区跃龙南路182号数字大厦一楼<br />
        备 案 号 ：<a
          href="https://beian.miit.gov.cn"
          target="_blank"
          style="color: #bbb"
          >苏ICP备2023029300号</a
        >
        <!-- -1 是cn ，-2是 com-->
        <!-- <a :href="href" target="_blank" style="color: #bbb; margin-left: 20px"
          >切换网站</a
        > -->
      </div>
    </div>
    <div class="code">
      <div class="header">扫码了解更多</div>
      <div class="code-content">
        <div class="code-item">
          <div class="img">
            <img src="../assets/images/wx-code.jpg?v=2" />
          </div>
          <span class="code-text">微信扫码</span>
        </div>
        <!--  <div class="code-item">
          <div class="imgs">
            <img
              data-v-1754b8f1=""
              src="../assets/images/footer_trill_code.png"
              alt="抖音号"
            />
          </div>
          <span class="code-text">抖音号</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps,  onMounted } from "vue"; // ref,

const props = defineProps(["position"]);
console.log("props: " + JSON.stringify(props));

// 根据域名备案号切换
// const beian = ref("苏ICP备2023029300号-2");
// const href = ref("https://www.x-vup.com");
// const currentDomain = window.location.hostname;
onMounted(() => {
  //if (currentDomain.includes("x-vup.cn")) {
    // beian.value = "苏ICP备2023029300号-1";
    // href.value = "https://www.x-vup.cn";
  //}
});
</script>

<style scoped lang="scss">
.foot-box {
  width: 100%;
  height: 250px;
  background: #040d1e;
  padding: 20px 155px 20px 155px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  text-align: left;
  .title {
    font-size: 18px;
    color: #bbb;
    margin-bottom: 24px;
  }
  .divider {
    width: 28px;
    height: 6px;
    background: #bbb;
    margin-bottom: 29px;
  }
  .info-content {
    font-size: 14px;
    color: #bbb;
    line-height: 30px;
  }
  .code {
    margin-left: auto;
  }
  .header {
    font-size: 18px;
    color: #bbb;
    margin-bottom: 24px;
  }
  .code-content {
    display: flex;
  }
  .code-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .code-item:first-child {
    margin-right: 30px;
  }
  .img {
    height: 106px;
    border-radius: 6px;
    padding: 8px;
    width: 106px;
    background: #fff;
    display: flex;
    margin-bottom: 9px;
  }
  .imgs {
    height: 106px;
    border-radius: 6px;
    width: 106px;
    background: #fff;
    display: flex;
    margin-bottom: 9px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .code-text {
    font-size: 14px;
    color: #bbb;
    text-align: center;
  }
  .record {
    position: absolute;
    bottom: 33px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
}

.foot-logo {
  width: 95px;
  height: 110px;
  margin-right: 115px;
}
</style>
