import { createRouter, createWebHistory } from "vue-router"; // createWebHashHistory
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      keepAlive: true,
      title:
        "元主持-为您专业制作数字分身，提供数字直播、AI视频创作服务！- 元好问旗下网站",
    },
  },
  {
    path: "/alterEgo",
    name: "alterEgo",
    meta: {
      keepAlive: true,
      title: "元主持-为您专业制作数字分身，提供数字人制作！",
    },
    component: () => import("../views/AlterEgo.vue"),
  },
  {
    path: "/shortVideo",
    name: "shortVideo",
    meta: {
      keepAlive: true,
      title: "元主持-为您专业提供AI视频创作、数字人制作服务！",
    },
    component: () => import("../views/ShortVideo.vue"),
  },
  {
    path: "/liveBroadcast",
    name: "liveBroadcast",
    meta: {
      keepAlive: true,
      title: "元主持-为您提供数字人直播服务！",
    },
    component: () => import("../views/LiveBroadcast.vue"),
  },
  {
    path: "/partnerProgram",
    name: "partnerProgram",
    meta: {
      keepAlive: true,
      title: "元主持-渠道合作邀您共赢！",
    },
    component: () => import("../views/PartnerProgram.vue"),
  },
  {
    path: "/mobilePhone",
    name: "mobilePhone",
    meta: {
      keepAlive: true,
      title: "元主持-为您专业制作数字分身，提供数字直播、AI视频创作服务！",
    },
    component: () => import("../views/MobilePhone.vue"),
  },
  {
    path: "/mAlterEgo",
    name: "mAlterEgo",
    meta: {
      keepAlive: true,
      title: "元主持-为您专业制作数字分身，提供数字人制作！",
    },
    component: () => import("../views/mAlterEgo.vue"),
  },
  {
    path: "/mShortVideo",
    name: "mShortVideo",
    meta: {
      keepAlive: true,
      title: "元主持-为您专业提供AI视频创作、数字人制作服务！",
    },
    component: () => import("../views/mShortVideo.vue"),
  },
  {
    path: "/mLiveBroadcast",
    name: "mLiveBroadcast",
    meta: {
      keepAlive: true,
      title: "元主持-为您提供数字人直播服务！",
    },
    component: () => import("../views/mLiveBroadcast.vue"),
  },
  {
    path: "/mPartnerProgram",
    name: "mPartnerProgram",
    meta: {
      keepAlive: true,
      title: "元主持-渠道合作邀您共赢！",
    },
    component: () => import("../views/mPartnerProgram.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // createWebHashHistory(),
  routes,
});

// 设置动态页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "元主持";
  next();
});

export default router;
