<template>
  <div>
    <nav-item v-model:navActive="navActive"></nav-item>

    <mv-full-page
      v-if="isInit"
      ref="myFullPage"
      :pages="4"
      v-model:page="page"
      :config="config"
      @rollEnd="onRollEnd"
      @pointerMouseover="pointerMouseover"
      @loaded="onLoaded"
    >
      <template #page1>
        <div class="home-box flex-cc">
          <div class="video-bg-box">
            <video
              ref="video1"
              webkit-playsinline="true"
              playsinline="true"
              preload="auto"
              src="../assets/media/home-bg.mp4"
              poster="../assets/images/img-11-0.png"
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              class="video-home"
            ></video>
          </div>
          <div class="home-content flex-cs">
            <div class="home-box-left">
              <div class="video-left animate-fadeInDown">
                <video
                  ref="video2"
                  webkit-playsinline="true"
                  playsinline="true"
                  src="../assets/media/home_left.mp4"
                  poster="../assets/images/img-11-1.png"
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                ></video>
              </div>
              <div class="video-right animate-fadeInDown">
                <video
                  ref="video3"
                  webkit-playsinline="true"
                  playsinline="true"
                  src="../assets/media/home_right.mp4"
                  poster="../assets/images/img-11-2.png"
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                ></video>
              </div>
            </div>
            <div class="home-box-right">
              <h1 class="title animate-fadeInDown">元主持平台</h1>
              <p class="desc animate-fadeInDown">源于数字，智胜真人</p>
              <div class="mg-t-90 animate-fadeInUp">
                <div class="flex-cc">
                  <span class="btn-s" @click="showSubmit">
                    <span class="text">数智人直播</span>
                    <svg
                      t="1692084456020"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="6598"
                      width="20"
                      height="20"
                    >
                      <path
                        d="M983.738821 312.687215a512.185033 512.185033 0 0 0-943.365513 0 512.426118 512.426118 0 0 0 272.426023 670.939489A512.426118 512.426118 0 0 0 983.738821 711.200681a513.149373 513.149373 0 0 0 0-398.513466zM791.83518 559.919858L596.315264 755.560317a36.765459 36.765459 0 1 1-51.953813-52.074355L699.258549 548.709407H249.152898a36.765459 36.765459 0 0 1 0-73.530918H699.258549L544.361451 320.401934a36.765459 36.765459 0 0 1 25.916635-62.802637 36.162746 36.162746 0 0 1 26.037178 10.728282l195.519916 195.640458a67.865421 67.865421 0 0 1 0 96.433991z"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </span>
                  <a class="btn-s mg-l-20" :href="href" target="_blank">
                    <span class="text">短视频创作</span>
                    <svg
                      t="1692084456020"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="6598"
                      width="20"
                      height="20"
                    >
                      <path
                        d="M983.738821 312.687215a512.185033 512.185033 0 0 0-943.365513 0 512.426118 512.426118 0 0 0 272.426023 670.939489A512.426118 512.426118 0 0 0 983.738821 711.200681a513.149373 513.149373 0 0 0 0-398.513466zM791.83518 559.919858L596.315264 755.560317a36.765459 36.765459 0 1 1-51.953813-52.074355L699.258549 548.709407H249.152898a36.765459 36.765459 0 0 1 0-73.530918H699.258549L544.361451 320.401934a36.765459 36.765459 0 0 1 25.916635-62.802637 36.162746 36.162746 0 0 1 26.037178 10.728282l195.519916 195.640458a67.865421 67.865421 0 0 1 0 96.433991z"
                        fill="#FFFFFF"
                        p-id="6599"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="page1">
          <div class="section flex-cc">
            <div class="flex-cc">
              <div class="content-box">
                <div class="flex-cc">
                  <div class="video-left rel">
                    
                  </div>
                </div>
                <div class="flex-cc">
                  
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </template>
      <template #page2>
        <div class="page2 page-img" style="position: relative">
          <div class="flex-cc">
            <div class="content-box">
              <div class="flex-cc flex-direction-column w-100">
                <div class="w-100 animate__animated animate__fadeInUp">
                  <h1 class="title-h2 center">服务产品</h1>
                </div>
                <div class="flex-cs w-100 mg-t-50">
                  <div class="card animate__animated animate__fadeInUp">
                    <div class="top rel">
                      <div class="top-img-box">
                        <img
                          src="../assets/images/home_two_right2.png"
                          class="top-img"
                        />
                      </div>
                      <div class="title-box">
                        <div class="top-title">数字人制作</div>
                        <div class="caption">释放创造力，拓展可能性</div>
                      </div>

                      <span
                        class="btn-card"
                        style="background-color: #e9007e"
                        @click="showSubmit"
                      >
                        <span class="mg-r-15 b">立即体验</span>
                        <svg
                          t="1692084456020"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="6598"
                          width="18"
                          height="18"
                        >
                          <path
                            d="M983.738821 312.687215a512.185033 512.185033 0 0 0-943.365513 0 512.426118 512.426118 0 0 0 272.426023 670.939489A512.426118 512.426118 0 0 0 983.738821 711.200681a513.149373 513.149373 0 0 0 0-398.513466zM791.83518 559.919858L596.315264 755.560317a36.765459 36.765459 0 1 1-51.953813-52.074355L699.258549 548.709407H249.152898a36.765459 36.765459 0 0 1 0-73.530918H699.258549L544.361451 320.401934a36.765459 36.765459 0 0 1 25.916635-62.802637 36.162746 36.162746 0 0 1 26.037178 10.728282l195.519916 195.640458a67.865421 67.865421 0 0 1 0 96.433991z"
                            fill="#fff"
                            p-id="6599"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div class="footer-live flex-cs" style="flex-wrap: wrap">
                      <span class="list-item flex-cc">视频采集</span>
                      <span class="list-item flex-cc">声音采集</span>
                      <span class="list-item flex-cc">训练分身</span>
                      <span class="list-item flex-cc">极致还原</span>
                      <span class="list-item flex-cc" style="width: 100%"
                        >源于数智，媲美真人</span
                      >
                    </div>
                  </div>

                  <div class="card animate__animated animate__fadeInUp">
                    <div class="top rel">
                      <div class="top-img-box">
                        <img
                          src="../assets/images/home_two_left.png"
                          class="top-img"
                        />
                      </div>
                      <div class="title-box">
                        <div class="top-title">数智人直播</div>
                        <div class="caption">开启“智”播时代</div>
                      </div>
                      <span class="btn-card" @click="showSubmit">
                        <span class="mg-r-15 b">立即体验</span>
                        <svg
                          t="1692084456020"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="6598"
                          width="18"
                          height="18"
                        >
                          <path
                            d="M983.738821 312.687215a512.185033 512.185033 0 0 0-943.365513 0 512.426118 512.426118 0 0 0 272.426023 670.939489A512.426118 512.426118 0 0 0 983.738821 711.200681a513.149373 513.149373 0 0 0 0-398.513466zM791.83518 559.919858L596.315264 755.560317a36.765459 36.765459 0 1 1-51.953813-52.074355L699.258549 548.709407H249.152898a36.765459 36.765459 0 0 1 0-73.530918H699.258549L544.361451 320.401934a36.765459 36.765459 0 0 1 25.916635-62.802637 36.162746 36.162746 0 0 1 26.037178 10.728282l195.519916 195.640458a67.865421 67.865421 0 0 1 0 96.433991z"
                            fill="#FFFFFF"
                            p-id="6599"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div class="footer-live flex-cs" style="flex-wrap: wrap">
                      <span class="list-item flex-cc">真人形象</span>
                      <span class="list-item flex-cc">动作多变</span>
                      <span class="list-item flex-cc">自动回复</span>
                      <span class="list-item flex-cc">智能互动</span>
                      <span class="list-item flex-cc" style="width: 100%"
                        >7*24小时无人直播</span
                      >
                    </div>
                  </div>

                  <div class="card animate__animated animate__fadeInUp">
                    <div class="top rel">
                      <div class="top-img-box">
                        <img
                          src="../assets/images/home_two_right.png"
                          class="top-img"
                        />
                      </div>
                      <div class="title-box">
                        <div class="top-title">短视频制作</div>
                        <div class="caption">摆脱重复拍摄困扰</div>
                      </div>

                      <a
                        class="btn-card"
                        style="background-color: #fff"
                        :href="href"
                        target="_blank"
                      >
                        <span class="mg-r-15 b" style="color: #635cf6"
                          >立即体验</span
                        >
                        <svg
                          t="1692084456020"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="6598"
                          width="18"
                          height="18"
                        >
                          <path
                            d="M983.738821 312.687215a512.185033 512.185033 0 0 0-943.365513 0 512.426118 512.426118 0 0 0 272.426023 670.939489A512.426118 512.426118 0 0 0 983.738821 711.200681a513.149373 513.149373 0 0 0 0-398.513466zM791.83518 559.919858L596.315264 755.560317a36.765459 36.765459 0 1 1-51.953813-52.074355L699.258549 548.709407H249.152898a36.765459 36.765459 0 0 1 0-73.530918H699.258549L544.361451 320.401934a36.765459 36.765459 0 0 1 25.916635-62.802637 36.162746 36.162746 0 0 1 26.037178 10.728282l195.519916 195.640458a67.865421 67.865421 0 0 1 0 96.433991z"
                            fill="#635cf6"
                            p-id="6599"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div class="footer-live flex-cs" style="flex-wrap: wrap">
                      <span class="list-item flex-cc">写稿/录音</span>
                      <span class="list-item flex-cc">自动合成</span>
                      <span class="list-item flex-cc">降本增效</span>
                      <span class="list-item flex-cc">极速量产</span>
                      <span class="list-item flex-cc" style="width: 100%"
                        >AI智能文案生成及改写</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #page3>
        <div class="page4 bg-4 flex-cc">
          <div class="flex-cc flex-direction-column">
            <h1 class="title-h2 animate__animated animate__fadeInUp">
              技术架构
            </h1>
            <div class="check mg-t-20 animate__animated animate__fadeInUp">
              <div class="checkList">
                <div class="check-item">
                  <svg
                    t="1692084456020"
                    class="icon"
                    viewBox="0 0 1365 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                  >
                    <path
                      d="M522.42040326 1015.22187429a149.88022912 149.88022912 0 0 1-107.43406847-45.43182222L58.59782172 605.83786163a157.44390649 157.44390649 0 0 1 0-219.34662979 149.68118521 149.68118521 0 0 1 214.81837693 0l248.8051607 254.27887503L1096.46367193 54.28658017a149.68118521 149.68118521 0 0 1 214.81837599 0 157.44390649 157.44390649 0 0 1 0 219.3466298l-681.72614204 696.1568421a149.78070716 149.78070716 0 0 1-107.13550262 45.43182222z"
                      fill="#635cf6"
                    ></path>
                  </svg>
                </div>
                <div class="check-title">强大的核心技术</div>
              </div>
              <div class="checkList">
                <div class="check-item">
                  <svg
                    t="1692084456020"
                    class="icon"
                    viewBox="0 0 1365 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                  >
                    <path
                      d="M522.42040326 1015.22187429a149.88022912 149.88022912 0 0 1-107.43406847-45.43182222L58.59782172 605.83786163a157.44390649 157.44390649 0 0 1 0-219.34662979 149.68118521 149.68118521 0 0 1 214.81837693 0l248.8051607 254.27887503L1096.46367193 54.28658017a149.68118521 149.68118521 0 0 1 214.81837599 0 157.44390649 157.44390649 0 0 1 0 219.3466298l-681.72614204 696.1568421a149.78070716 149.78070716 0 0 1-107.13550262 45.43182222z"
                      fill="#635cf6"
                    ></path>
                  </svg>
                </div>
                <div class="check-title">统一的能力平台</div>
              </div>
              <div class="checkList">
                <div class="check-item">
                  <svg
                    t="1692084456020"
                    class="icon"
                    viewBox="0 0 1365 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                  >
                    <path
                      d="M522.42040326 1015.22187429a149.88022912 149.88022912 0 0 1-107.43406847-45.43182222L58.59782172 605.83786163a157.44390649 157.44390649 0 0 1 0-219.34662979 149.68118521 149.68118521 0 0 1 214.81837693 0l248.8051607 254.27887503L1096.46367193 54.28658017a149.68118521 149.68118521 0 0 1 214.81837599 0 157.44390649 157.44390649 0 0 1 0 219.3466298l-681.72614204 696.1568421a149.78070716 149.78070716 0 0 1-107.13550262 45.43182222z"
                      fill="#635cf6"
                    ></path>
                  </svg>
                </div>
                <div class="check-title">标准的产品服务</div>
              </div>
            </div>
            <div class="flex-cc mg-t-80 animate__animated animate__fadeInUp">
              <img src="../assets/images/custom_center.png" class="img-6" />
            </div>
          </div>
        </div>
      </template>
      <template #page4>
        <div class="page5 flex-cc">
          <div class="content-box">
            <div class="flex-cc flex-direction-column">
              <div
                class="center animate__animated animate__fadeInUp"
                style="margin-top: -140px"
              >
                <h1 class="title-h2 c3">关于我们</h1>
                <!-- <p
                  class="title-desc c3 b center w-100 animate__animated animate__fadeInUp"
                >
                </p> -->
              </div>
              <div class="animate__animated animate__fadeInUp">
                <div class="about">
                  元主持(X-VUP)由南通元好问科技发展有限公司联合顶尖AI技术团队创立，致力于运用自研多模态大模型、认知推理、因果模型等前沿AI技术，为用户提供可控内容生成及虚实交互解决方案。目前已经在IP数字分身、AI协同创作、企业数智服务等领域形成标化产品。
                </div>
                <div class="about">
                  元主持平台是元好问科技旗下首个标准化应用产品，集中于人物的理解与内容生成，基于多模态AIGC与互动技术，打造媲美真人的形象、声音、表情、动作、个性的AI数智人，应用于视频合成、直播、个性化交互等场景，为客户提供数智人定制、视频制作、直播、交互型服务等标准化高效率解决方案。
                </div>
              </div>
              <div
                class="img-items mg-t-10 flex-cc animate__animated animate__fadeInUp"
                style="flex-wrap: wrap"
              >
                <img :src="link1" class="img-item" />
                <img :src="link2" class="img-item" />
                <img :src="link3" class="img-item" />
                <img :src="link4" class="img-item" />
                <img :src="link5" class="img-item" />
                <img :src="link6" class="img-item" />
                <img :src="link7" class="img-item" />
                <img :src="link8" class="img-item" />
              </div>
              <div
                class="animate__animated animate__fadeInUp"
                style="z-index: 10001; position: relative"
              >
                <span
                  class="btn-s"
                  style="
                    width: 300px;
                    height: 68px;
                    z-index: 10002;
                    position: relative;
                  "
                  @click="showSubmit"
                >
                  <span class="text" style="font-size: 22px">合作咨询</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <vup-foot></vup-foot>
      </template>
    </mv-full-page>
    <div>
      <submit-form ref="submitFormRef"></submit-form>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  watch,
  nextTick,
  defineAsyncComponent,
} from "vue"; //  defineComponent,   toRefs , watch, nextTick
// import { useRouter, useRoute } from "vue-router";
import VupFoot from "../components/VupFoot.vue";
import SubmitForm from "../components/SubmitForm.vue";
import NavItem from "../components/NavItems.vue";
import {
  link1,
  link2,
  link3,
  link4,
  link5,
  link6,
  link7,
  link8,
} from "../assets/js/iconBase64";
import { useRouter, useRoute } from "vue-router"; //
// import MvFullPage from "mv-full-page";
import "../../node_modules/mv-full-page/dist-lib/style.css"; // 用相对路径引用css 兼容webpack和vite

const isInit = ref(false);
const router = useRouter();
const route = useRoute();
const navActive = ref(1);

const deviceType = ref("");
const isMobile = () => {
  // console.log(window.innerWidth);
  return window.innerWidth <= 768; // 设置手机端的阈值宽度
};

// ！！异步加载组件，解决跳转后无法滑动问题
const MvFullPage = defineAsyncComponent(() => import("mv-full-page"));

onBeforeMount(() => {
  deviceType.value = isMobile() ? "手机端" : "非手机端";
  if (deviceType.value === "手机端") {
    router.push({
      name: "mobilePhone",
    });
  } else {
    isInit.value = true;
  }
  // 在组件挂载之前执行滚动到顶部的操作
  // window.scrollTo(0, 0);
});

const video1 = ref(null);
const video2 = ref(null);
const video3 = ref(null);
watch(
  () => route.path,
  () => {
    nextTick(() => {
      try {
        console.log("video1.value", video1);
        const _v1 = video1.value.play();
        console.log("_v1", _v1);
        video2.value.play();
        video3.value.play();
      } catch (error) {
        console.log("error: " + JSON.stringify(error));
      }
    });
    // console.log(newPath, oldPath);
  },
  { immediate: true }
);

const submitFormRef = ref(null);
const showSubmit = () => {
  if (submitFormRef.value) {
    // console.log(submitFormRef.value);
    submitFormRef.value.changeDialogFormVisible();
  }
};

const page = ref(1);
const config = {
  disabled: false,
  direction: "v",
  cache: true,
  bgArr: [],
  //  循环播放
  loop: false,
  // 自动轮播
  autoPlay: {
    play: false,
    // 切换间隔
    interval: 1000,
  },
  arrow: {
    // 上一页箭头
    // last: true,
    // 下一页箭头
    // next: true,
  },
  poi: {
    pointer: false,
    // className: "my-poi",
  },
};

const myFullPage = ref();
const pointerMouseover = ({ event, index }) => {
  console.log(event, index);
};

// const toDisplay = ref(true);
// const page1 = ref(true);
// const page2 = ref(false);
// const page3 = ref(false);
// const page4 = ref(false);
// const page5 = ref(false);

// 处理页面切换时，重新加载动画
const onRollEnd = () => {
  // const pageValues = [false, true, false, false, false];
  // if (page >= 1 && page <= 5) {
  //   pageValues.fill(false);
  //   pageValues[page - 1] = true;
  // }
  // [page1.value, page2.value, page3.value, page4.value, page5.value] =
  //   pageValues;
  // console.log("当前页面为", page);
};

// const switchDire = () => {
//   state.config.direction == "v"
//     ? (state.config.direction = "h")
//     : (state.config.direction = "v");
// };

const onLoaded = () => {
  // 所有资源已经加载完毕
};
onMounted(() => {
  // 监听路由参数的变化
  // watch(
  //   () => route.query,
  //   (query) => {
  //     const params = query.message ? Number(query.message) : 1;
  //     nextTick(() => {
  //       page.value = params;
  //       myFullPage.value.goPage(params, true);
  //       showPage(params);
  //     });
  //   },
  //   { immediate: true } // 设置 immediate 为 true，立即执行监听函数
  // );
});
// // 使用 useRoute 获取当前路由信息
// const route = useRoute();
const href = ref("https://vm.x-vup.com/");
// const toLogin = () => {
//   window.location.href = "https://vm.x-vup.com/";
// };
</script>
<style lang="scss" scoped>
@import "./home.scss";

.home-box {
  .video-bg-box {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .home-content {
    z-index: 10;
    width: 1200px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    // overflow-y: hidden;
  }
  .home-box-left {
    position: relative;
    .video-left {
      width: 594px;
      video {
        border-radius: 16px;
        border: 6px solid #010101;
        height: 100%;
        width: 100%;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .video-right {
      width: 185px;
      height: 320px;
      position: absolute;
      top: 80px;
      right: -100px;
      video {
        border-radius: 16px;
        border: 6px solid #010101;
        height: 100%;
        width: 100%;
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 10;
      }
    }
  }
  .home-box-right {
    .title {
      font-size: 66px;
      margin-bottom: 34px;
      margin-top: 0;
      font-weight: 500;
      color: #fff;
    }
    .des {
      font-weight: 500;
      color: #fff;
      font-size: 30px;
    }
  }
}
</style>
