<template>
  <div>
    <el-dialog v-model="dialogFormVisible" :show-close="false" width="840px">
      <div class="slogon">
        <div class="title">需求留言问卷</div>
        <div class="desc">
          感谢您对元主持平台的关注，<br />请留下您的信息，<br />我们会尽快联系您！
        </div>
        <div class="qr-code">
          <div class="code-wrapper">
            <img src="../assets/images/wx-code.jpg?v=2" alt="咨询" />
          </div>
          <div class="text">了解更多，一键扫码咨询</div>
        </div>
      </div>
      <div class="ant-modal-content">
        <div>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
            status-icon
          >
            <el-form-item label="需求类型" prop="type">
              <!-- <el-radio-group
                v-model="ruleForm.type"
                fill="#635cf6"
                text-color="#635cf6"
              >
                <el-radio label="数智人直播" />
                <el-radio label="制作分身" />
                <el-radio label="加盟推广" />
              </el-radio-group> -->
              <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox label="数字分身" name="type" />
                <el-checkbox label="短视频创作" name="type" />
                <el-checkbox label="数智人直播" name="type" />
                <el-checkbox label="合作加盟" name="type" />
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="姓名" prop="realName">
              <el-input v-model="ruleForm.realName" />
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group
                v-model="ruleForm.sex"
                fill="#635cf6"
                text-color="#635cf6"
              >
                <el-radio label="男" />
                <el-radio label="女" />
              </el-radio-group>
            </el-form-item>
            <el-form-item label="联系方式" prop="mobile" required>
              <el-input v-model="ruleForm.mobile" />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input v-model="ruleForm.companyName" />
            </el-form-item>
            <el-form-item label="企业邮箱" prop="email">
              <el-input v-model="ruleForm.email" />
            </el-form-item>
            <el-form-item label="职位" prop="job">
              <el-input v-model="ruleForm.job" />
            </el-form-item>
            <el-form-item label="需求描述" prop="requirement">
              <el-input v-model="ruleForm.requirement" type="textarea" />
            </el-form-item>
            <el-form-item>
              <el-button @click="resetForm(ruleFormRef)" size="large"
                >取消</el-button
              >
              <el-button
                color="#6d61f1"
                :dark="isDark"
                @click="submitForm(ruleFormRef)"
                size="large"
                :loading="loading"
              >
                提交
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineExpose } from "vue"; //  defineComponent,   toRefs emit  defineProps
import { useDark } from "@vueuse/core"; // useToggle  自定义按钮颜色
import axios from "axios";
import { ElMessage } from "element-plus";

const isDark = useDark(); // 自定义按钮颜色
const loading = ref(false);

const _url = "https://testapi.htrc.cn/api/Msg/SaveXVUPBBS";
// const props = defineProps(["dialogFormVisible"]);
const dialogFormVisible = ref(false);
const ruleFormRef = ref(null);

// 提交表单
const ruleForm = reactive({
  type: [],
  realName: "",
  sex: "",
  mobile: "",
  companyName: "",
  email: "",
  job: "",
  requirement: "",
});

onMounted(() => {});

const changeDialogFormVisible = () => {
  dialogFormVisible.value = !dialogFormVisible.value;
};

defineExpose({
  changeDialogFormVisible,
});
// console.log(changeDialogFormVisible);
// const updateProp = (params) => {
//   const newValue = params;
//   dialogFormVisible.value = newValue;
//   emit("update:dialogFormVisible", newValue);
// };

const resetForm = (formEl) => {
  // console.log("formEl: " + JSON.stringify(formEl));
  if (!formEl) return;
  dialogFormVisible.value = false;
  formEl.resetFields();
  // console.log("1111111111111: " + JSON.stringify(1111111111111));
};

const submitForm = async (formEl) => {
  if (!formEl) return;
  loading.value = true;
  await formEl.validate((valid) => {
    // fields
    if (valid) {
      // console.log("submit!");
      toAxios();
      loading.value = false;
      resetForm(formEl);
    } else {
      // console.log("error submit!", fields);
      loading.value = false;
    }
  });
};

const toAxios = () => {
  ruleForm.type = ruleForm.type.join(",");

  axios
    .post(_url, ruleForm)
    .then((res) => {
      console.log("res: " + JSON.stringify(res));
      // 处理响应数据
      dialogFormVisible.value = false;
      ElMessage.success("提交成功！");
    })
    .catch((err) => {
      console.log("err: " + JSON.stringify(err));
      // 处理错误
    });
};

// 手机号验证规则
const validatePhon = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入手机号码"));
  } else if (
    !/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/.test(
      value
    )
  ) {
    callback(new Error("请输入正确的手机号码"));
  } else {
    callback();
  }
};

const rules = reactive({
  type: [
    {
      required: true,
      message: "必填",
      trigger: "change",
    },
  ],
  realName: [
    {
      required: true,
      message: "必填",
      trigger: "change",
    },
  ],
  sex: [
    {
      required: true,
      message: "必填",
      trigger: "blur",
    },
  ],
  mobile: [
    { validator: validatePhon, trigger: "blur" },
    {
      pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
      message: "请输入正确的手机号",
      trigger: "blur",
    },
  ],
});
</script>

<style lang="scss" scoped>
.slogon {
  flex: 0.33;
  width: 340px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background-image: url(../assets/images/view-page-modal.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 60px 40px;
  position: absolute;
  left: 0;
  top: 0;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
  }
  .qr-code {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .code-wrapper {
      width: 160px;
      background: #fff;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 14px;
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      white-space: nowrap;
    }
  }
}

.ant-modal-content {
  margin-left: 304px;
  padding: 0 30px 20px;
  height: 560px;
}
</style>
