import { createApp } from "vue";
import ElementPlus from "element-plus";
import "./assets/css/base.scss"; // 导入全局样式表
import "./assets/css/animate.min.css";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css"; // 基于断点的隐藏类
import "animate.css";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.use(router);
app.use(ElementPlus);
// app.use(MvFullPage);
app.mount("#app");
