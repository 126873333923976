<template>
  <nav
    class="flex-cs nav-box"
    :style="navActive === 3 ? 'background-color:rgba(255,255,255,1);' : ''"
  >
    <div class="flex-cfs">
      <div class="flex-cc">
        <img
          src="../assets/images/logoBlack.png"
          class="logo"
          v-if="navActive === 3"
        />
        <img src="../assets/images/logo.png" class="logo" v-else />
      </div>
      <div class="mg-l-32">
        <ul class="header-wrapper">
          <li
            @click="toPage(item.name)"
            v-for="(item, index) in navList"
            :key="index"
          >
            <a
              :class="navActive === index + 1 ? 'active-a' : ''"
              :style="navActive === 3 ? 'color:#666' : ''"
              >{{ item.lable }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-cfs">
      <el-popover
        :width="190"
        popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
      >
        <template #reference>
          <el-button color="#6d61f1" :dark="isDark">扫码咨询</el-button>
        </template>
        <template #default>
          <div
            class="demo-rich-conent"
            style="display: flex; gap: 16px; flex-direction: column"
          >
            <img
              src="../assets/images/wx-code.jpg?v=2"
              style="width: 150px; height: 150px"
            />
          </div>
        </template>
      </el-popover>
      <div class="ant-divider" v-if="loginShow"></div>
      <div
        v-if="loginShow"
        @click="toLogin"
        :style="
          navActive === 3
            ? 'color: #666; cursor: pointer'
            : 'color: #fff; cursor: pointer'
        "
      >
        注册/登录
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue"; //  defineComponent,   toRefs , watch, nextTick watchEffect
import { useDark } from "@vueuse/core"; // useToggle  自定义按钮颜色
import { useRouter } from "vue-router"; // useRoute

const loginShow = ref(true);
onMounted(() => {
  if (window.location.hostname.includes("x-vup.cn")) {
    loginShow.value = false;
  }
});

const router = useRouter();
const navList = ref([
  { lable: "首页", name: "home" },
  { lable: "数字分身", name: "alterEgo" },
  { lable: "短视频创作", name: "shortVideo" },
  { lable: "数智直播", name: "liveBroadcast" },
  { lable: "伙伴计划", name: "partnerProgram" },
]);
const isDark = useDark(); // 自定义按钮颜色

// 接收v-model传来的值，并绑定

const props = defineProps(["navActive"]);
const navActive = ref(props.navActive);
defineEmits(["update:navActive"]);

// console.log("props: " + JSON.stringify(props));
// const props = defineProps({
//   page: {
//     type: Number,
//     default: 1,
//   },
// });

// const emits = defineEmits(["update:page"]);
// const internalValue = ref(props.page);
// watchEffect(() => {
//   emits["update:page"](internalValue.value);
// });

// END

const toPage = (params) => {
  router.push({
    name: params,
  });
};

// 使用 useRoute 获取当前路由信息
// const route = useRoute();

const toLogin = () => {
  // window.location.href = "https://vm.x-vup.com/";
  window.open("https://vm.x-vup.com/", "_blank");
};
</script>
<style lang="scss" scoped>
.nav-box {
  top: 0;
  left: 0;
  right: 0;
  padding: 0 60px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  height: 62px;
  width: 100%;
  position: fixed;
  box-sizing: border-box;
  transition: background-color 1s;
  .logo {
    height: 52px;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      width: 96px;
      text-align: center;
      margin-right: 32px;
      font-size: 16px;
      font-weight: 600;
      a {
        color: #bbb;
        cursor: pointer;
      }
    }
  }
}

.active-a {
  color: #6d61f1 !important;
}
</style>
